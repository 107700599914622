import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/accounts/search",
        name: "accounts.search",
        component: () => import("../views/Accounts/Search.vue"),
    },
    {
        path: "/vendors/search",
        name: "vendors.search",
        component: () => import("../views/Vendors/Search.vue"),
    },

    {
        path: "/settings",
        name: "settings",
        component: () => import("../views/Settings.vue"),
    },
];

const router = new VueRouter({
    routes,
});

export default router;
