<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
    data: () => ({
        selected: null,
        options: [
            { value: null, text: "Please select an option" },
            { value: "a", text: "This is First option" },
            { value: "b", text: "Selected Option" },
            {
                value: { C: "3PO" },
                text: "This is an option with object value",
            },
            { value: "d", text: "This one is disabled", disabled: true },
        ],
    }),
    async mounted() {
        this.init();
    },
    methods: {
        async init() {
            await ZOHO.embeddedApp.on("PageLoad", (response) => {
                // console.log("pageload data: ", response);
                this.$store.dispatch("setEntityData", response);
            });
            await ZOHO.embeddedApp.init().then(() => {
                // console.log("init done");
            });

            ZOHO.CRM.API.getOrgVariable(
                "zohocrm2abn__Fillable_Properties"
            ).then((data) => {
                const settings = JSON.parse(data.Success.Content);
                // console.log("crm data: ", settings);

                this.$store.dispatch("setFillableSettings", settings);
            });

            // ZOHO.CRM.CONFIG.getOrgInfo().then((data) => {
            //     console.log("getOrgInfo", data);
            // });

            // ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", {
            //     apiname: "zohocrm2abn__TestVariableSS",
            //     value: "111111111111",
            // }).then((e) => {
            //     console.log(e);
            // });

            // await ZOHO.CRM.META.getFields({ Entity: "Vendors" }).then(
            //     (data) => {
            //         console.log("getFields", data);
            //     }
            // );

            // await ZOHO.CRM.CONFIG.getCurrentUser().then((data) => {
            //     console.log("curentUSer: ", data);
            // });
        },
    },
};
</script>
