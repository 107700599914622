export default {
    setEntityData(state, payload) {
        state.entityData.entity = payload.Entity;
        state.entityData.entityId = payload.EntityId;
        state.entityData.data = payload.Data;
    },
    setFillableSettings(state, payload) {
        state.fillableSettings = payload;
    },
};
